.memberContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 100%;
}
.tableContainer {
    max-height: 105%;
    overflow-y: auto;
}
.tableContainer::-webkit-scrollbar-track
{
	border-radius: 10px;
    background-color: #F5F5F5 !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.tableContainer::-webkit-scrollbar
{
	width: 6px;
    background-color: #F5F5F5 !important;
}

.tableContainer::-webkit-scrollbar-thumb
{
	border-radius: 10px;
    background-color: #313131bd !important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
}
.memberTable {
    max-height: calc(100%);
}
.permissionItemTitle {
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
    color: #36354557;
}
.iconContainer {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #3635450a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.iconContainer:hover {
    background-color: #3635452a;
}
.borderRight {
    border-right: 1px solid #3635454a;
}