.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  margin: 0;
}
.noHover {
  pointer-events: none;
}
.customScroll::-webkit-scrollbar-track,
.sider .ant-layout-sider-children::-webkit-scrollbar-track
{
	border-radius: 10px;
  background-color: #F5F5F5 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
}

.customScroll::-webkit-scrollbar,
.sider .ant-layout-sider-children::-webkit-scrollbar
{
	width: 6px;
  background-color: #F5F5F5 !important;
}

.customScroll::-webkit-scrollbar-thumb,
.sider .ant-layout-sider-children::-webkit-scrollbar-thumb
{
	border-radius: 10px;
  background-color: #313131bd !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
}
.sider .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100dvh;
  overflow-y: auto;
}
.leftMenu .ant-menu-item {
  margin-inline: 0 !important;
  border-radius: 0px !important;
  width: 100%;
  min-height: 50px;
  opacity: 0.7;
}
.leftMenu.ant-menu-light .ant-menu-item-selected {
  background: #0c77be47;
  opacity: 1;
}
.leftMenu.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):active, 
.leftMenu.ant-menu-light .ant-menu-item:not(.ant-menu-item-selected):hover {
  background-color: #0c77be9d !important;
  opacity: 1;
} 
.ant-spin-nested-loading,
.ant-spin-container {
  height: 100%;
}
.ant-layout-header {
  padding-inline: 15px !important;
}
.projectSelect .ant-select-selection-item {
  font-weight: 600;
}
.listView-table table tr > th,
.listView-table table tr > td{
  background-color: #f3f3f7 !important;
  border-bottom: 1px solid #c6c6c661 !important;
}
.listView-table table tbody tr:hover > td {
  background-color: #e5e5eeb4 !important;
}
:is(.memberTable, .listView-table) .ant-table-column-sorters {
  justify-content: flex-start;
  gap: 5px;
}
:is(.memberTable, .listView-table) .ant-table-column-sorters .ant-table-column-title{
  flex: unset;
}

.selectView .ant-segmented-item-label {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
}
.fileBreadcrum li {
  height: 30px;
  line-height: 30px;
}
.fileDetail .ant-drawer-close {
  display: none;
}
.fileDetail .ant-drawer-header {
  border-bottom: none;
  padding-bottom: 0;
}
.fileDetail .ant-tabs-nav-list {
  width: 100%;
}
.fileDetail .ant-tabs-tab {
  flex: 1;
  justify-content: center;
} 
.searchInput .ant-input-suffix {
  margin-inline-start: 0 !important;
}
.searchInput .ant-input {
  background-color: #36354500;
  opacity: 0;
}
.searchInput.ant-input-affix-wrapper-focused .ant-input{
  opacity: 1;
}
.memberTable .ant-table-thead>tr>th {
  padding-block: 2px !important;
}
.memberTable .ant-table-thead>tr>th,
.memberTable .ant-table-thead>tr>td,
.memberTable .ant-table-tbody>tr>td {
  background-color: #f3f3f7 !important;
  border-color:  #cccccc96 !important;
}
.memberTable .ant-table-thead>tr>.ant-table-cell:hover {
  background-color: #d6d6d660 !important;

}
.memberTable .ant-table-tbody>tr:hover>td {
  background-color: #c2c2c242 !important;

}
.memberTable .ant-table-cell {
  padding: 12px 0 !important;
}
@media only screen and (max-width: 576px) {
  .projectItem .ant-list-item-meta {
    flex-direction: column;
    height: 100%;
  }
  .projectItem .ant-list-item-meta-content {
    width: 100% !important;
  }
}