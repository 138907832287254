.contentPage {
    max-height: calc(100dvh - 60px);
    width: 100%;
    overflow: hidden;
    padding: 10px 20px 0;
    background-color: #f3f3f7;
    position: relative;
    display: flex;
    flex-direction: column;
}
.titleContainer {
    display: flex;
    align-items: center;
    gap: 5px;
}
.pageTitle {
    color: #363545 !important;
    margin-block: 10px !important;
    max-width: 500px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.mainContent {
    width: 100%;
    max-height: 100%;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    min-height: calc(100% - 52px);
}
.invincible {
    display: none;
}
@media only screen and (max-width: 576px) {
    .contentPage {
        padding-inline: 10px !important;
    }
}