.viewContainer {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 14px;
    right: 8px;
    z-index: 6;
}
.viewItemContainer {
    display: flex;
    height: 100%;
}
.viewImg {
    width: 100%;
    height: auto;
}
/* grid view */
.gridViewController {
    display: flex;
    gap: 50px;
    padding: 17px 15px 0;
}
.gridViewFiles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
    max-height: 100%;
    overflow-y: auto;
    width: 100%;
    padding-bottom: 20px;
}
.gridViewFileItem {
    width: 176px;
    height: 176px;
    background: white;
    border-radius: 8px;
    overflow: hidden;
    padding: 1px;
    position: relative;
}
.iconContainer {
    width: 100%;
    height: 139px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.fileInfo {
    border-radius: 8px;
    padding: 8px 12px;
    width: 100%;
    background-color: #F7F9FA;
    display: flex;
    justify-content: space-between;
}
.fileName {
    display: inline-block;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.fileCheckbox {
    position: absolute;
    top: 15px;
    left: 15px;
}
/* 
tree view files */
.treeViewFiles {
    display: flex;
    flex-direction: row;
    gap: 15px;
    max-height: 100%;
    min-height: 100%;
}
.treeView {
    /* resize: horizontal; */
    display: flex;
    flex-direction: column;
    height: 100%;
    width: min(236px, 20%);
    border-right: 1px solid #E2E2E7;
    max-height: 100%;
    overflow-y: auto;
}
.treeView > * {
    margin-left: 0 !important;
}