.projectItem {
    background-color: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
}
.projectItem:hover {
    box-shadow: 1px 1px 1px 2px rgba(0, 0, 0, 0.1);
}
@media only screen and (max-width: 576px) {
    .projectItem {
        padding-inline: 10px !important;
    }
}