.shareItem {
    display: flex;
    flex-direction: row;
    gap: 10px;
    min-height: 44px;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    padding: 5px 0;
}
.shareItem:hover {
    background: #00000009;
}
.iconContainer {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    background-color: #2195f3dc;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon {
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(106deg) brightness(104%) contrast(101%);
}
.itemContent {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}