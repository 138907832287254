
.LeftMenu {
  background-color: #00437B;
  /* overflow: auto; */
  height: fit-content;
  padding: 14px 0;
  color: #004b8865
}
.sider {
  background-color: #00437B !important;
  max-height: 100%;
}
