.folder {
  margin-left: 15px;
  user-select: none;
}
.folderActive {
  
  background-color: rgba(0, 0, 0, 0.04);
}
.folderItem {
  padding: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  gap: 6px;
  cursor: pointer;
  align-items: center;
  border-radius: 5px;
} 
.folderItem:hover {
  background-color: rgba(0, 0, 0, 0.02);
}
.folderTitle {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.file {
  user-select: none;
  margin: 0;
  padding: 5px 0;
  margin-left: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;
}
.fileName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}